




























































































































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import api from "@/core/utils/api";
import {
  RateVideoAction,
  Rating,
  Seminar,
  SimpleUser,
  Submission,
} from "@/core/models";
import VideoSubmission from "../components/VideoSubmission.vue";
import { videoTypes } from "@/core/utils/seminars";

@Component({ components: { VideoSubmission } })
export default class SeminarVideos extends Vue {
  @Getter("seminars/selected") selected?: Seminar;
  @Getter("seminars/users") users!: SimpleUser[];
  @Getter("profile/id") userId!: string;
  @Action("seminars/get") getSeminar!: (id: number) => Promise<void>;

  // filters controller
  sort: "" | "name" | "date" | "amusing" | "creative" | "professional" = "";
  get allSorts() {
    return [
      {
        icon: "mdi-account",
        text: "Name",
        value: "name",
      },
      {
        icon: "mdi-calendar",
        text: this.$t("seminars.videos.sortBy.date").toString(),
        value: "date",
      },
      {
        emoji: "&#128558;",
        text: this.$t("seminars.ratings.amusing").toString(),
        value: "amusing",
      },
      {
        emoji: "&#127912;",
        text: this.$t("seminars.ratings.creative").toString(),
        value: "creative",
      },
      {
        emoji: "&#128188;",
        text: this.$t("seminars.ratings.professional").toString(),
        value: "professional",
      },
    ];
  }

  // users controller
  search = "";
  selectedUsers: string[] = [];
  get filteredUsers() {
    if (!this.search) return this.users;
    return this.users.filter(x => {
      const term = this.search.toLowerCase();
      return (
        x.email.toLowerCase().includes(term) ||
        x.username.toLowerCase().includes(term)
      );
    });
  }

  // element controller
  elId = 0;
  get elItems() {
    return this.selected?.elements
      ?.filter(x => videoTypes.includes(x.type))
      .map(x => ({
        text: x.title,
        value: x.id,
      }));
  }

  getting = false;
  submissions: Submission[] = [];
  async getSubmissions(id: number) {
    this.getting = true;
    try {
      const end = `/api/Submissions/SharedVideos/${id}`;
      const list = (await api.get(end)) as Submission[];
      this.submissions = list;
    } catch (error) {
      console.log(error);
    }
    this.getting = false;
  }

  elName(s: Submission) {
    return (
      this.selected?.elements?.find(x => x.id === s.elementId)?.title ||
      "<<not_found>>"
    );
  }

  get filteredSubmissions() {
    const userFilter = (x: Submission) =>
      !this.selectedUsers.length ? true : this.selectedUsers.includes(x.userId);

    const elFilter = (x: Submission) =>
      !this.elId || this.elId === 0 ? true : x.elementId === this.elId;

    // apply user and element filter
    const list = this.submissions.filter(userFilter).filter(elFilter);

    if (this.sort === "name")
      return list.sort((a, b) => this.elName(b).localeCompare(this.elName(a)));
    else if (this.sort === "date")
      return list.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      );
    else if (this.sort === "") return list;
    else
      return list.sort((a, b) => {
        const bRatings = (b as any).videoRatings as Rating[];
        const aRatings = (a as any).videoRatings as Rating[];
        const bCount = bRatings.filter(x => x.type === this.sort).length;
        const aCount = aRatings.filter(x => x.type === this.sort).length;
        return bCount - aCount;
      });
  }

  rated(data: RateVideoAction) {
    // find submission
    const idx = this.submissions.findIndex(x => x.id === data.submissionId);
    if (idx === -1) return;

    // get all ratings
    const newSubs = this.submissions.slice(0);
    const ratings = ((newSubs[idx] as any).videoRatings || []) as Rating[];

    // find rating
    const existing = ratings.findIndex(
      x => x.rater === this.userId && x.type === data.type,
    );

    // add new rating
    if (existing === -1)
      ratings.push({
        id: (ratings[ratings.length - 1]?.id || 0) + 1,
        submissionId: data.submissionId,
        rater: this.userId,
        type: data.type,
        createdAt: new Date().toISOString(),
      });
    // remove old rating
    else ratings.splice(existing, 1);

    // update array
    (newSubs[idx] as any).videoRatings = ratings;
    this.submissions = newSubs;
  }

  created() {
    const id = this.$route.params.id;
    this.getSeminar(Number(id));
    this.getSubmissions(Number(id));
  }

  resetFilters() {
    this.elId = 0;
    this.search = "";
    this.selectedUsers = [];
    this.sort = "";
  }

  @Watch("selected")
  seminarChanged() {
    if (!this.selected) return;
    this.resetFilters();
    this.getSubmissions(this.selected.id);
  }
}
